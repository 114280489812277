<template>
  <div>
    <!-- 表单 -->
    <a-form :model="formData" layout="vertical" class="form" :rules="rules">
      <!-- 归档流程名称 -->
      <a-form-item label="归档流程名称" name="recordsName">
        <a-input
          :maxlength="60"
          placeholder="请输入归档流程名称"
          v-model:value="formData.recordsName"
          style="width: 372px;"
          @input="
            formData.recordsName = formData.recordsName.replace(
              /[^\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》\/！“”（）【】<>\/!''()\[\]-]+/g,
              ''
            )
          "
        />
      </a-form-item>
      <!-- 文件类型 -->
      <a-form-item label="文件类型" class="form-item" name="fileType" :autoLink="false">
        <a-spin :spinning="fileTypeLoading">
          <div class="form-tips-view">
            <div
              @click="Typemodal()"
              class="printer-seal"
              :style="{
                padding: fileTypeList.length ? '6px 24px 1px 10px' : '0 0 0 12px',
                'line-height': fileTypeList.length ? 'auto' : '32px',
                maxHeight: '100px',
                overflow: 'auto'
              }"
            >
              <template v-if="fileTypeList.length">
                <div v-for="(item, index) in fileTypeList" :key="item.dataId" class="pt-tag">
                  <span class="pt-name" :title="item.dataName" v-if="item.dataName.length <= 12">{{
                    item.dataName
                  }}</span>
                  <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
                    <template #title>
                      <span>{{ item.dataName }}</span>
                    </template>
                    <div class="lang-name" style="max-width: 150px;color:black">
                      {{ `${item.dataName.substring(0, 12)}...` }}
                    </div>
                  </a-tooltip>
                  <div @click.stop="deleteSta(index, 1)" class="pt-close">
                    <CloseOutlined />
                  </div>
                </div>
              </template>
              <span v-else>请选择文件类型</span>
              <DownOutlined class="down-arrow" />
            </div>
          </div>
        </a-spin>
      </a-form-item>
      <!-- 流程名称负责人 -->
      <a-form-item label="归档流程负责人" name="custodianList">
        <div class="proces">
          <div class="pro-item" v-for="(el, index) in custodianList" :key="el.dataId">
            <img :src="el.icon" alt class="pro-item-icon" />
            <i class="iconfont icon-cha1 remove-arrow" @click="delPerson(index)" />
            <div class="pro-name" v-if="el.dataName?.length < 4">{{ el.dataName }}</div>
            <a-popover v-else :getPopupContainer="e => e.parentNode" overlayClassName="lang-name-pop">
              <div class="lang-text-name">{{ el.dataName }}</div>
              <template #content>
                <div>{{ el.dataName }}</div>
              </template>
            </a-popover>
          </div>
          <img
            :class="['iconfont', 'icon-add', 'icon-add-right']"
            v-if="!custodianList || custodianList.length < 10"
            @click="handleDepartment()"
            src="@/assets/images/business/chang-process.png"
            alt
            class="process-up"
          />
        </div>
      </a-form-item>
      <a-form-item label="适用范围" class="form-item" name="fileType" :autoLink="false">
        <a-spin :spinning="applicableLoading">
          <div class="form-tips-view">
            <div
              @click="applicablemodal()"
              class="printer-seal"
              :style="{
                padding: scopeList.length ? '6px 24px 1px 10px' : '0 0 0 12px',
                'line-height': scopeList.length ? 'auto' : '32px',
                maxHeight: '100px',
                overflow: 'auto'
              }"
            >
              <template v-if="scopeList.length">
                <div v-for="(item, index) in scopeList" :key="item.dataId" class="pt-tag">
                  <span class="pt-name" :title="item.dataName" v-if="item.dataName.length <= 14">{{
                    item.dataName
                  }}</span>
                  <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
                    <template #title>
                      <span>{{ item.dataName }}</span>
                    </template>
                    <div class="lang-name" style="max-width: 150px;color:black">
                      {{ `${item.dataName.substring(0, 13)}...` }}
                    </div>
                  </a-tooltip>
                  <div @click.stop="deleteSta(index, 2)" class="pt-close">
                    <CloseOutlined />
                  </div>
                </div>
              </template>
              <span v-else>请选择适用范围</span>
              <DownOutlined class="down-arrow" />
            </div>
          </div>
        </a-spin>
      </a-form-item>
      <a-form-item label="说明" name="remark" class="remark">
        <a-textarea
          :autoSize="{ minRows: 5 }"
          v-model:value="remark"
          placeholder="请输入说明"
          :maxlength="500"
          showCount="inside"
          @pressEnter="e => lineFeed(e)"
          style="width: 372px"
          @input="
            formData.recordsName = formData.recordsName.replace(
              /[^\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》\/！“”（）【】<>\/!''()\[\]-]+/g,
              ''
            )
          "
        />
      </a-form-item>
    </a-form>
    <!-- TODO :统一选择成员 -->
    <a-modal
      centered
      title="添加成员"
      width="655px"
      class="global-range-modal"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <GlobalSelector
        :selectorInfo="selectorInfo"
        :visible="visible"
        :selectedList="custodianList"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        @changeData="changeData"
        ref="globalSelector"
      ></GlobalSelector>
      <!--selectorInfo:弹窗格式  visible:是否显示  selectedList:选中后的数据  renderList左侧列表的数据
        getSearchInfo搜索触发的方法 toNext点击上级后触发的方法  changeData切换数据类型(企业,员工)
        -->
    </a-modal>
    <a-modal
      centered
      title="请选择文件类型"
      width="655px"
      class="global-range-modal"
      :visible="fileVisible"
      @cancel="fileCancel"
      @ok="fileOk"
    >
      <GlobalSelector
        :selectorInfo="fileSelectorInfo"
        :visible="fileVisible"
        :selectedList="fileTypeList"
        :renderList="fileRenderList"
        @getSearchInfo="getType"
        ref="globalSelector"
      ></GlobalSelector>
    </a-modal>
    <a-modal
      centered
      title="请选择适用范围"
      width="655px"
      class="global-range-modal"
      :visible="applicableVisible"
      @cancel="applicableCancel"
      @ok="applicableOk"
    >
      <GlobalSelector
        :selectorInfo="applicableSelectorInfo"
        :visible="applicableVisible"
        :selectedList="scopeList"
        :renderList="applicableRenderList"
        @getSearchInfo="getAllOrganization"
        ref="globalSelector"
      ></GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
// import RangeModal from '@/components/rangeModal'
import { CloseOutlined, DownOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'
import { getTypeList, getArchiveTypeList } from '@/apis/businessManage'
import { getOrganization } from '@/apis/archive'
import { getProcessName } from '@/apis/process'
import { useRoute } from 'vue-router'
export default {
  name: 'CcConfig',
  components: {
    // RangeModal,
    DownOutlined,
    CloseOutlined,
    GlobalSelector
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showConfig: {
      type: Boolean,
      defaulte: false
    },
    isCancel: {
      type: Boolean,
      defaulte: false
    }
  },

  data() {
    const $route = useRoute()
    const getBlur = async (rule, value) => {
      // console.log('流程名称输入框失焦',JSON.parse(localStorage.getItem('yda-admin-userInfo')).result)
      // console.log('6666666',this.$store.state.process);
      if (!value) {
        return Promise.reject('流程名称不可为空')
      } else {
        const params = {
          // enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId,
          processName: value,
          processId: $route.query.enterpriseId,
          processType: undefined,
          processMold: 2
        }
        // console.log('判断是否已存在名字')
        const isRepeat = await getProcessName(params)
        if (!isRepeat.success) {
          return Promise.reject('流程名称不可重复')
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      recordsName: '',
      custodianList: [],
      fileTypeList: [],
      scopeList: [],
      remark: '',
      ccType: '1',
      formData: {
        recordsName: ''
      },
      selectorInfo: {
        abelCheckAll: true,
        limitCount: 10,
        placeholder: '请输入员工姓名或部门',
        ableSelectDep: true,
        ableChangeData: true
      },
      fileSelectorInfo: {
        placeholder: '请输入文件类型名称查询',
        abelCheckAll: false,
        ableSelectDep: false,
        isShowBreadcrumb: false,
        isShowSelectedList: true
      },
      applicableSelectorInfo: {
        isSingleChoice: false, //[是否为单选]
        isShowSelectedList: true, //[是否展示已选的列表]
        isShowBreadcrumb: false, //[是否显示面包屑]
        placeholder: '请输入公司名称查询',
        abelCheckAll: false,
        isSelectDept: true,
        ableSelectDep: true,
        ableChangeData: false
      },
      visible: false,
      fileVisible: false,
      applicableVisible: false,
      renderList: [],
      fileRenderList: [],
      applicableRenderList: [],
      selectorType: 1,
      fileTypeLoading: false,
      applicableLoading: false,
      rules: {
        recordsName: [
          { required: true, validator: getBlur, trigger: 'blur' }, // 必填验证
          {
            pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》/！“”（）【】<>/!""()\\[\\]-]+$',
            message: '归档流程名称不支持emoji表情',
            trigger: 'blur'
          }
        ],
        fileType: [
          {
            required: true
          }
        ],
        custodianList: [
          {
            required: true
          }
        ]
      }
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    allDisabled() {
      return this.$store.state.process.isPreview
    }
  },
  watch: {
    showConfig: {
      deep: true,
      handler(value) {
        console.log('瞅瞅selectedNode.props里面有什么', this.selectedNode.props)
        this.getInitList()
        const select = JSON.parse(JSON.stringify(this.selectedNode.props))
        if (value) {
          if (!this.isCancel) {
            // console.log('抽屉显示', select);
            this.ccType = select?.ccType ? '2' : '1'
            select.cc.custodianList?.forEach(item => {
              item.dataType = 1
            })
            this.custodianList = select.cc.custodianList
            this.formData.recordsName = select.cc.recordsName
            this.fileTypeList = select.cc.fileTypeList
            this.scopeList = select.cc.scopeList
            this.remark = select.cc.remark
          }
        } else {
          // 没点保存
          if (!this.isCancel) {
            this.formData.recordsName = ''
            this.custodianList = []
            ;(this.fileTypeList = []), (this.scopeList = []), (this.remark = '')
            this.ccType = '1'
          } else {
            // 点了保存
            this.custodianList?.forEach(item => {
              item.dataType = 'user'
            })
            this.selectedNode.props.ccType = this.ccType === '2'
            this.selectedNode.props.cc =
              this.ccType === '2'
                ? {
                    recordsName: '',
                    custodianList: [],
                    fileTypeList: [],
                    scopeList: [],
                    remark: ''
                  }
                : {
                    custodianList: this.custodianList,
                    recordsName: this.formData.recordsName,
                    fileTypeList: this.fileTypeList,
                    scopeList: this.scopeList,
                    remark: this.remark
                  }
          }
        }
      }
    }
  },
  created() {
    this.getScopeList()
    console.log('this.selectedNode', this.selectedNode)
    if (this.selectedNode.props.cc) {
      const select = JSON.parse(JSON.stringify(this.selectedNode.props))
      select.cc.custodianList?.forEach(item => {
        item.dataType = 1
      })
      console.log('看看select.cc里面有什么2222222222', this.$store.state.user.userInfo)
      this.custodianList = select.cc.custodianList
      this.formData.recordsName = select.cc.recordsName
      this.fileTypeList = select.cc.fileTypeList
      this.scopeList = select.cc.scopeList
      this.remark = select.cc.remark
      this.ccType = select.ccType ? '2' : '1'
    }
    //
    this.getType()
    this.getAllOrganization()
    this.getInitList()
  },
  methods: {
    getInitList() {
      const user = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
      console.log('检测发起页面是不是需要添加默认业务流程负责人', user, this.custodianList.length)
      if (user && this.custodianList.length == 0) {
        console.log('进来了')
        this.custodianList.push({
          islower: false,
          staffName: user.nickname,
          dataName: user.nickname,
          dataType: 1,
          staffId: user.result.staffId,
          dataId: user.result.staffId,
          icon: user.avatar ? user.avatar : require('@/assets/images/default-avatar.png')
        })
      }
    },
    Typemodal() {
      this.fileVisible = true
    },
    applicablemodal() {
      this.applicableVisible = true
    },
    // 添加成员
    handleDepartment() {
      //业务负责人
      this.visible = true
    },
    deleteSta(index, type) {
      switch (type) {
        case 1:
          //文件类型
          this.fileTypeList.splice(index, 1)
          // formRef.value.validate('trusteesList')
          break
        case 2:
          //适用范围
          this.scopeList.splice(index, 1)
      }
    },
    // 删除成员
    delPerson(index) {
      this.custodianList.splice(index, 1)
    },

    //弹窗取消事件
    handleCancel() {
      this.visible = false
      this.$refs.globalSelector.cancelEvent()
    },
    fileCancel() {
      this.fileVisible = false
      this.$refs.globalSelector.cancelEvent()
    },
    applicableCancel() {
      this.applicableVisible = false
      this.$refs.globalSelector.cancelEvent()
    },
    //弹窗确定事件
    handleOk() {
      this.custodianList = []
      let arr = [...this.$refs.globalSelector.okEvent()]
      transformData(arr).then(res => {
        res.forEach(item => {
          this.custodianList.push({
            dataId: item.dataId,
            dataName: item.dataName,
            dataType: 1,
            sex: null,
            selected: true,
            icon: item?.icon,
            mobile: item?.mobile
          })
        })
      })
      this.handleCancel()
    },
    fileOk() {
      this.fileTypeLoading = true
      this.fileTypeList = []
      let arr = [...this.$refs.globalSelector.okEvent()]
      transformData(arr).then(res => {
        res.forEach(item => {
          this.fileTypeList.push({
            dataId: item.dataId,
            dataName: item.dataName,
            dataType: 1,
            sex: null,
            selected: true,
            icon: item?.icon,
            mobile: item?.mobile
          })
        })
        this.fileTypeLoading = false
      })
      this.fileCancel()
      console.log('点击确定', this.fileTypeList)
    },
    applicableOk() {
      this.applicableLoading = true
      this.scopeList = []
      this.scopeList = [...this.$refs.globalSelector.okEvent()]
      this.applicableLoading = false
      this.applicableCancel()
    },
    //搜索
    getSearchInfo(e) {
      let data = {
        searchName: e
      }
      this.getScopeList(data)
    },

    //去下级
    toNext(id) {
      let data = {
        departmentId: id
      }
      this.getScopeList(data)
    },
    //人员弹窗数据
    async getScopeList(data) {
      let params = {
        selectorType: this.selectorType, //1部门人员2部门3员工
        allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        this.handelData(res)
      }
    },
    //文件类型
    async getType(data) {
      let params = {
        searchTypeName: data
      }
      const res = await getArchiveTypeList(params)
      if (res.success) {
        console.log('全部文件类型数据', res)
        this.fileRenderList = res.data.map(item => ({
          dataName: item.typeName, //数据名称
          dataType: 1, //数据类型
          dataId: item.id, //Id
          icon:
            item.processId == null
              ? require('@/assets/images/fileType.png')
              : require('@/assets/images/filetype-uncheckable.png'), //头像标识
          processId: item.processId,
          disabled: item.processId == null ? false : true
          // mobile://手机号
          // members//部门下人数
        }))
      }
    },
    async getAllOrganization(data) {
      let params = {}
      if (data) {
        params = {
          searchName: data,
          selectorType: 1
        }
      } else {
        params = { selectorType: 4 }
      }
      getOrganization(params).then(res => {
        console.log('已绑定归档流程的文件', res)
        if (res.success) {
          this.applicableRenderList = res.data.departmentList.map(item => ({
            dataName: item.departmentName,
            dataId: item.departmentId,
            dataType: 2, //数据类型,
            icon: require('@/assets/images/checkStaff/level.png')
          }))
        }
      })
    },
    // 处理左侧数据
    handelData(res) {
      this.renderList = formateListData(res)
      console.log('处理后的renderList的值', this.renderList)
    },

    changeData({ isOrganizationData, searchValue }) {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        this.selectorType = 1
      } else {
        //人员数据
        this.selectorType = 3
      }
      this.getSearchInfo(searchValue)
      console.log('当前选中的成员111', this.custodianList)
    }
  }
}
</script>

<style scoped lang="less">
.form {
  :deep(.ant-form label) {
    font-size: 16px;
  }

  .form-item {
    :deep(.has-error) .form-tips-view .printer-seal {
      border-color: #0A7BFF;
    }
  }

  .person {
    .person-header {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;

      .person-header-right {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }

    .personel {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      display: flex;
      flex-flow: wrap;
      margin: 12px 0px;
      text-align: center;

      .per-item {
        color: rgba(0, 0, 0, 0.65);
        padding: 2px 4px;
        font-size: 14px;
        margin-right: 12px;
        margin-bottom: 12px;

        .icon {
          height: 40px;
          width: 40px;
          background-size: cover;
          position: relative;
        }

        .item-icon {
          font-size: 12px;
          color: white;
          background-color: #0A7BFF;
          border-radius: 100%;
          position: absolute;
          right: 0;
          border: 1px solid white;
        }
      }

      .icon-add-right {
        color: #0A7BFF;
        cursor: pointer;
        font-size: 24px;
        margin-top: -6px;
      }
    }
  }

  .proces {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .process-up {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .pro-item {
      .pro-item-icon {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }

      .remove-arrow {
        position: relative;
        bottom: 30px;
        right: 12px;
        color: #0A7BFF;
        font-size: 12px;
        background: #ffffff;
        height: 12px;
        line-height: 12px;
        border-radius: 50%;
        cursor: pointer;
      }

      .lang-text-name {
        cursor: pointer;
        width: 50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .text {
    background-color: #fbfbfb;
    border-radius: 2px;
    padding: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 26px;
  }
}

.printer-seal {
  width: 372px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: #bfbfbf;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  padding: 6px 24px 1px 10px;
  transition: border-color 0.3s;

  &::-webkit-scrollbar {
    display: block;
  }

  &:hover {
    border-color: #0A7BFF;
  }

  .pt-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 5px 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .pt-name {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }

  .pt-close {
    width: 14px;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #333;
    }
  }

  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}
.remark {
  :deep(.ant-input-textarea-show-count::after) {
    position: relative;
    top: -30px;
    right: 10px;
  }
}
</style>
