import request from '@/utils/request'
export function getAllType(params) {
  return request({
    url: '/pc/file/type',
    method: 'GET',
    params
  })
}
export function getArchiveList(params) {
  return request({
    url: '/pc/document/archive',
    method: 'GET',
    params
  })
}

export function putArchiveSubmit(data) {
  return request({
    url: '/pc/document/archive/submit',
    method: 'PUT',
    data
  })
}
export function getOrganization(params) {
  return request({
    url: '/pc/organization/selector',
    method: 'GET',
    params
  })
}
export function submitArchive(params) {
  return request({
    url: '/pc/sealComplete/submit',
    method: 'POST',
    data: params
  })
}

export function getArchiveVetting(params) {
  return request({
    url: `/pc/workspace/archive/process/info`,
    method: 'GET',
    params
  })
}

export function reSubmitArchive(params) {
  return request({
    url: `/pc/sealComplete/archive/details`,
    method: 'GET',
    params
  })
}

export function getArchiveDetail(id) {
  return request({
    url: `/pc/document/archive/${id}`,
    method: 'GET'
  })
}

export function getBindProcessType(params) {
  return request({
    url: `/pc/file/type/all`,
    method: 'GET',
    params
  })
}

export function putArchiveRemark(data) {
  return request({
    url: '/pc/document/archive',
    method: 'PUT',
    data
  })
}